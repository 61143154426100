import { useCallback, useEffect } from "react"

import api from "api"
import { saveAuthInfo } from "utils/auth"
import { SSO_URL, USER_TYPE } from "utils/constants"

type Props = {
  onLogin?: () => void
  onLogout: () => void
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useIframeMessagingAuth = ({ onLogout, onLogin = () => {} }: Props) => {
  const getPlatformToken = useCallback(async (code: string) => {
    const res = await api.auth.generatePlatformToken({
      data: {
        code,
        platform: USER_TYPE,
      },
    })
    if (res.isSuccessful) {
      saveAuthInfo(res.data)
      onLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const receiveMessage = (event: MessageEvent<{ code: string }>) => {
      if (event.origin === SSO_URL) {
        // console.log("Event rcvd", event.data)
        if ("code" in event.data) {
          if (event.data.code) {
            getPlatformToken(event.data.code)
          }
          // if (event.data.code === null && Boolean(getAuthInfo())) {
          //   clearAuthInfo()
          //   onLogout()
          // }
        }
      }
    }
    if (!window !== undefined) {
      window.addEventListener("message", receiveMessage)
      return () => window.removeEventListener("message", receiveMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPlatformToken])
}

export default useIframeMessagingAuth
