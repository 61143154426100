import React from "react"

import { CircularProgress, Typography } from "@suraasa/placebo-ui"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 8,
  },
})

const GlobalSignInLoading = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="title1">Please wait while we sign you in</Typography>
      <CircularProgress />
    </div>
  )
}

export default GlobalSignInLoading
