import { AuthData } from "api/resources/users/types"
import { NetCore } from "utils/tracking/netcore"
import { webengage } from "utils/webengage"

type Service = "WebEngage" | "NetCore"

const allServices: Service[] = ["WebEngage", "NetCore"]

export const trackingService = {
  initUser: (
    authInfo: {
      user: Pick<AuthData["user"], "firstName" | "lastName" | "uuid" | "email">
    },
    services = allServices
  ) => {
    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.user.login(authInfo.user.uuid)
        webengage.user.setAttribute("we_email", authInfo.user.email)
        webengage.user.setAttribute("we_first_name", authInfo.user.firstName)
        webengage.user.setAttribute("we_last_name", authInfo.user.lastName)
      }
    } catch (e) {
      console.warn("Error initialising user on WebEngage", e)
    }

    try {
      if (services.includes("NetCore")) {
        NetCore.putAttribute("pk^customerid", authInfo.user.uuid)
        NetCore.putAttribute("FIRST_NAME", authInfo.user.firstName)
        NetCore.putAttribute("LAST_NAME", authInfo.user.lastName)
        NetCore.putAttribute("email", authInfo.user.email)
      }
    } catch (e) {
      console.warn("Error initialising user on NetCore", e)
    }
  },
  trackEvent: (eventName: string, data?: any, services = allServices) => {
    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.track(eventName, data)
      }
    } catch (e) {
      console.warn(`Error tracking event on WebEngage: ${eventName}`, data)
      console.warn(e)
    }

    try {
      if (services.includes("NetCore")) {
        /**
         * This case could happen. Because NetCore has a sloppy implementation of when smartech and Hansel are defined on the window object.
         * There could be a case where "smartech" is defined but "Hansel" is not. So we try again after 500ms to ensure that "Hansel" was defined.
         */
        if (NetCore.getHansel() == null) {
          setTimeout(() => {
            NetCore.logEvent(eventName, data)
          }, 500)
        } else {
          NetCore.logEvent(eventName, data)
        }
      }
    } catch (e) {
      console.warn(`Error tracking event on NetCore: ${eventName}`, data)
      console.warn(e)
    }
  },
  setUserAttribute: (key: string, value: any, services = allServices) => {
    // console.log(`> Tracking user attribute: ${key}=${value}`)
    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.user.setAttribute(key, value)
      }
    } catch (e) {
      console.warn(`Error tracking user attribute on WebEngage: ${key}`, value)
      console.warn(e)
    }

    try {
      if (services.includes("NetCore")) NetCore.putAttribute(key, value)
    } catch (e) {
      console.warn(`Error tracking user attribute on NetCore: ${key}`, value)
      console.warn(e)
    }
  },
}
