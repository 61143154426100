import React, { useEffect, useState } from "react"

import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import { differenceInDays, isAfter } from "date-fns"
import Link from "next/link"
import { useRouter } from "next/router"
import { createUseStyles } from "react-jss"

import { Xmark } from "iconoir-react"

import api from "api"
import { Masterclass } from "api/resources/masterclasses/types"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { GA } from "utils/googleAnalytics"
import { parseParam } from "utils/utmParams"

const useStyles = createUseStyles({
  dialogContentRoot: {
    padding: 24,
  },
  img: {
    borderRadius: 8,
    width: "100%",
  },
})

const forbiddenParams = ["cpc", "paid social", "pmax"]

const MasterclassPopup = () => {
  const classes = useStyles()
  const router = useRouter()

  const [data, setData] = useState<Masterclass | null>(null)
  const [open, setOpen] = useState(true)

  const dismiss = () => {
    setOpen(false)

    localStorage.setItem(
      BROWSER_STORAGE_KEYS.masterclassPopupDismissed,
      new Date().toISOString()
    )
  }

  useEffect(() => {
    const getLatestMasterclass = async () => {
      const res = await api.masterclasses.list({
        urlParams: {
          page: 1,
          size: 1,
          ordering: "-start_time",
        },
      })
      if (res.isSuccessful) {
        if (res.data.data.length > 0) {
          setData(res.data.data[0])
        }
      }
    }
    getLatestMasterclass()
  }, [])

  if (!data) return null

  // Don't wanna show popup for ito masterclasses
  if (data.slug.startsWith("ito-")) return null

  if (router.pathname.includes("/talk-to-a-mentor")) return null
  if (router.pathname.includes("/masterclasses/join")) return null
  if (
    router.pathname.includes("/masterclasses/[slug]") &&
    router.query.slug === data.slug
  )
    return null
  if (router.pathname.includes("/masterclasses/register")) return null
  if (router.pathname.includes("/masterclasses/certificate/get")) return null

  if (router.pathname.startsWith("/verify")) return null
  if (router.pathname.startsWith("/ito/verify")) return null
  if (router.pathname.startsWith("/international-teachers-olympiad/verify"))
    return null

  // Don't wanna show the popup when specific utm medium is present in the URL
  const utmMedium = parseParam(router.query.utm_medium)
  if (utmMedium && forbiddenParams.includes(utmMedium.toLowerCase()))
    return null

  const lastDismissedAt = localStorage.getItem(
    BROWSER_STORAGE_KEYS.masterclassPopupDismissed
  )

  // Only show the dialog once every 24 hours
  if (lastDismissedAt) {
    const daysSinceDismissed = differenceInDays(
      new Date(),
      new Date(lastDismissedAt)
    )

    if (daysSinceDismissed < 1) return null
  }

  const isUpcoming = isAfter(new Date(data.startTime), new Date())
  if (!isUpcoming) return null

  return (
    <Dialog open={open} onRequestClose={dismiss}>
      <DialogContent className={classes.dialogContentRoot}>
        <div className="flex items-center justify-between mb-3">
          <Typography variant="title3">Upcoming Masterclass</Typography>
          <IconButton onClick={dismiss}>
            <Xmark />
          </IconButton>
        </div>

        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          alt="masterclass cover"
          className={classes.img}
          src={data.thumbnail || ""}
        />

        <Link
          href={`/masterclasses/register/${data.slug}?utm_source=Popup&utm_medium=Website`}
        >
          <Button
            className="mt-2"
            fullWidth
            {...GA.trackElement("masterclass-popup-register-btn", {
              feature: "masterclass",
              purpose: "register",
              source: "website",
            })}
            onClick={dismiss}
          >
            Register Now
          </Button>
        </Link>
      </DialogContent>
    </Dialog>
  )
}

export default MasterclassPopup
