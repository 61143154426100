/* eslint-disable import/no-cycle */
import camelCase from "lodash/camelCase"

import { AuthData } from "api/resources/users/types"
import { transformKeys } from "api/utils"
import { BROWSER_STORAGE_KEYS, SSO_URL } from "utils/constants"

import { LocalStorage, SessionStorage } from "./storageFactory"

export function getAuthInfo() {
  if (typeof window === "undefined") return null

  const authInfo = LocalStorage.getItem("auth")

  if (authInfo !== null) {
    const newAuthInfoObj: AuthData = transformKeys(
      JSON.parse(authInfo),
      camelCase
    )
    return newAuthInfoObj
  }

  return authInfo
}

const addSecondsToDate = (date: Date, seconds: number) =>
  new Date(date.setSeconds(date.getSeconds() + seconds))

export function saveAuthInfo(authInfo: AuthData) {
  const { auth } = BROWSER_STORAGE_KEYS

  const oldAuthToken = getAuthInfo()?.accessToken
  const newAuthToken = authInfo.accessToken

  LocalStorage.setItem(
    auth,
    JSON.stringify({
      ...authInfo,
      expiresAt:
        oldAuthToken !== newAuthToken
          ? // Reduce expiry time by 10 minutes so we can refresh prematurely
            addSecondsToDate(
              new Date(),
              authInfo.expiresIn - 10 * 60
            ).toISOString()
          : authInfo.expiresAt,
    })
  )
}

export function clearAuthInfo() {
  const { auth } = BROWSER_STORAGE_KEYS
  LocalStorage.removeItem(auth)
  SessionStorage.clear()
}

export const generateLoginURL = ({
  redirectUrl,
  next,
  ...rest
}: { redirectUrl: string; next: string } & { [key: string]: string }) => {
  let redirectTo = encodeURIComponent(redirectUrl)
  if (next) redirectTo = `${redirectTo}?next=${encodeURIComponent(next)}`

  const restParams = Object.keys(rest)
    .map(key => `${key}=${rest[key]}`)
    .join("&")
  const url = `${SSO_URL}/?redirect-url=${redirectTo}&${restParams}`

  return url
}
